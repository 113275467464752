import "./App.css";
import logo from "./icons/alpha.png";
import Header from "./common/Header";
import React, { useEffect, useState } from "react";
import "@fortawesome/fontawesome-free/css/all.min.css";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Link,
  useNavigate,
  Navigate,
} from "react-router-dom";
import Home from "./Home";
import Login from "./Login";
import Footer from "./common/Footer";
import "../src/css/general.css";
import LoginNew from "./LoginNew";
import PostDetail from "./blog/PostDetail";
import CreatePostButton from "./blog/CreatePostButton";
import PostForm from "./blog/PostForm";
import SignUp from "./common/Signup";

function App() {
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  //const token = localStorage.getItem("token");
  const token = sessionStorage.getItem("token");
  const userName = sessionStorage.getItem("userName");
  useEffect(() => {
    if (token) {
      setIsAuthenticated(true);
    }
  }, []);
  //setIsAuthenticated(true);
  const handleLogin = () => {
    setIsAuthenticated(true);
  };

  const handleLogout = () => {
    localStorage.removeItem("token");
    sessionStorage.removeItem("token");
    sessionStorage.removeItem("userName");
    sessionStorage.removeItem("userRole");
    setIsAuthenticated(false);
  };

  return (
    <Router>
      <div className="d-flex flex-column min-vh-100 main-body">
        <nav className="navbar sticky-top navbar-expand-lg navbar-light bg-light">
          <div className="container-fluid">
            {/* Logo visible on all screens */}
            <img
              src={logo}
              className="navbar-brand"
              style={{ height: "40px" }}
              alt="logo"
            />

            {/* Toggler for small screens */}
            <button
              className="navbar-toggler"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarNav"
              aria-controls="navbarNav"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon"></span>
            </button>

            {/* Optionally show the username on larger screens */}
            {userName && (
              <li className="nav-item d-none d-lg-block">
                <span className="navbar-text navText">{userName}</span>
              </li>
            )}

            {/* Collapsible Navbar section for small screens */}
            <div className="collapse navbar-collapse" id="navbarNav">
              <ul className="navbar-nav ms-auto centered-navbar-nav">
                {/* Show Home and Login/Logout buttons directly when toggled */}
                <li className="nav-item">
                  <Link className="nav-link btn navText" to="/">
                    Home
                  </Link>
                </li>
                <li className="nav-item">
                  {isAuthenticated ? (
                    <Link
                      className="nav-link btn navText"
                      onClick={handleLogout}
                    >
                      Logout
                    </Link>
                  ) : (
                    <Link className="nav-link btn navText" to="/login">
                      Login
                    </Link>
                  )}
                </li>
              </ul>
            </div>
          </div>
        </nav>

        <div className="container mt-4 flex-grow-1 d-flex flex-column justify-content-center min-vh-75">
          <Routes>
            <Route
              path="/login"
              element={
                isAuthenticated ? (
                  <Navigate to="/" />
                ) : (
                  // <Login onLogin={handleLogin} />
                  <LoginNew onLogin={handleLogin} />
                )
              }
            />
            <Route
              path="/"
              element={<Home isAuthenticated={isAuthenticated} />}
            />
            <Route path="/signup" element={<SignUp />} />
            <Route path="/posts/:id" element={<PostDetail />} />
            <Route path="/posts/add" element={<PostForm />} />
          </Routes>
        </div>

        <div className="flex-grow">
          <Footer />
        </div>
        {/* <CreatePostButton /> */}
      </div>
    </Router>
  );
}

export default App;
