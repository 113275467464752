import image from "../icons/user.png";
import style from "../css/postCard.module.css";
import axios from "axios";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import config from "../common/config";
import binIcon from "../icons/red-x-line-icon.png";

const PostCard = ({
  postId,
  authorName,
  postTitle,
  likeCount: initialLikeCount,
  updatedTime,
  onDelete,
}) => {
  const [likeCount, setLikeCount] = useState(initialLikeCount);
  const [hasLiked, setHasLiked] = useState(false);
  const [isHoverable, setIsHoverable] = useState(true);
  const nav = useNavigate();

  useEffect(() => {
    console.log("remove hover effect on small devices");
    const checkHoverCapability = () => {
      if (
        window.matchMedia("(max-width: 600px)").matches ||
        window.matchMedia("(hover: none)").matches
      ) {
        setIsHoverable(false); // Set hoverable to false if on a small screen or touch device
      } else {
        setIsHoverable(true); // Otherwise, allow hover effects
      }
    };
    checkHoverCapability();
    window.addEventListener("resize", checkHoverCapability);

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener("resize", checkHoverCapability);
    };
  }, []);
  const userRole = sessionStorage.getItem("userRole");
  const handleLikeClick = () => {
    if (!hasLiked) {
      setLikeCount(likeCount + 1);
      setHasLiked(true);
    } else {
      setLikeCount(likeCount - 1);
      setHasLiked(false);
    }
  };

  const postTitleClick = () => {
    nav(`/posts/${postId}`);
  };

  const handleDeleteClick = async () => {
    const confirmed = window.confirm(
      "Are you sure you want to delete this post?"
    );
    if (confirmed) {
      try {
        // Assuming the token is stored in localStorage
        const token = sessionStorage.getItem("token");
        const apiUrl = config.apiUrl;
        const response = await axios.delete(
          `${apiUrl}:4000/api/v1/blogs/${postId}`,
          {
            headers: {
              Authorization: `Bearer ${token}`, // Set the Bearer token in the Authorization header
            },
          }
        );

        if (response.status === 200) {
          // Axios uses response.status instead of response.ok
          console.log("Post deleted successfully");
          if (onDelete) onDelete(postId); // Update the UI, for example, remove the post from a list
        } else {
          console.error("Failed to delete the post");
        }
      } catch (error) {
        console.error("An error occurred while deleting the post:", error);
      }
    }
  };
  return (
    <section className={style.cards}>
      <article
        className={`${style.card} ${style.card1} ${
          isHoverable ? style.largeScreenHover : ""
        }`}
      >
        <div
          className={`${style.cardinfohover} ${
            isHoverable ? style.cardInfoHide : ""
          }`}
        >
          {hasLiked ? (
            <svg
              className={style.cardlike}
              onClick={handleLikeClick}
              viewBox="0 0 122.88 107.39"
            >
              <path
                fill="#ed1b24"
                d="M60.83,17.18c8-8.35,13.62-15.57,26-17C110-2.46,131.27,21.26,119.57,44.61c-3.33,6.65-10.11,14.56-17.61,22.32-8.23,8.52-17.34,16.87-23.72,23.2l-17.4,17.26L46.46,93.55C29.16,76.89,1,55.92,0,29.94-.63,11.74,13.73.08,30.25.29c14.76.2,21,7.54,30.58,16.89Z"
              />
            </svg>
          ) : (
            <svg
              className={style.cardlike}
              onClick={handleLikeClick}
              viewBox="0 0 24 24"
            >
              <path
                fill="#000000"
                d="M12.1,18.55L12,18.65L11.89,18.55C7.14,14.24 4,11.39 4,8.5C4,6.5 5.5,5 7.5,5C9.04,5 10.54,6 11.07,7.36H12.93C13.46,6 14.96,5 16.5,5C18.5,5 20,6.5 20,8.5C20,11.39 16.86,14.24 12.1,18.55M16.5,3C14.76,3 13.09,3.81 12,5.08C10.91,3.81 9.24,3 7.5,3C4.42,3 2,5.41 2,8.5C2,12.27 5.4,15.36 10.55,20.03L12,21.35L13.45,20.03C18.6,15.36 22,12.27 22,8.5C22,5.41 19.58,3 16.5,3Z"
              />
            </svg>
          )}

          {/* <svg
            className={style.cardlike}
            onClick={handleLikeClick}
            viewBox="0 0 24 24"
          >
            <path
              fill="#000000"
              d="M12.1,18.55L12,18.65L11.89,18.55C7.14,14.24 4,11.39 4,8.5C4,6.5 5.5,5 7.5,5C9.04,5 10.54,6 11.07,7.36H12.93C13.46,6 14.96,5 16.5,5C18.5,5 20,6.5 20,8.5C20,11.39 16.86,14.24 12.1,18.55M16.5,3C14.76,3 13.09,3.81 12,5.08C10.91,3.81 9.24,3 7.5,3C4.42,3 2,5.41 2,8.5C2,12.27 5.4,15.36 10.55,20.03L12,21.35L13.45,20.03C18.6,15.36 22,12.27 22,8.5C22,5.41 19.58,3 16.5,3Z"
            />
          </svg> */}
          <span className={style.likeCount}>{likeCount}</span>
          <div className={style.cardclockinfo}>
            {/* <svg className={style.cardclock} viewBox="0 0 24 24">
              <path d="M12,20A7,7 0 0,1 5,13A7,7 0 0,1 12,6A7,7 0 0,1 19,13A7,7 0 0,1 12,20M19.03,7.39L20.45,5.97C20,5.46 19.55,5 19.04,4.56L17.62,6C16.07,4.74 14.12,4 12,4A9,9 0 0,0 3,13A9,9 0 0,0 12,22C17,22 21,17.97 21,13C21,10.88 20.26,8.93 19.03,7.39M11,14H13V8H11M15,1H9V3H15V1Z" />
            </svg> */}
            {userRole === "superuser" && (
              <img
                className={style.cardclock}
                viewBox="0 0 24 24"
                src={binIcon}
                onClick={handleDeleteClick}
              />
            )}
            {/* <span className={style.cardtime}>15 min</span> */}
          </div>
        </div>
        <div className={style.cardimg}></div>
        <a className={style.cardlink}>
          <div className={style.cardimghover}></div>
        </a>
        <div className={style.cardinfo} onClick={postTitleClick}>
          <span className={style.cardcategory}>Life Style</span>
          <div className={style.cardtitle}>{postTitle} </div>
          <span className={style.cardby}>
            by{" "}
            <a className={style.cardauthor} title="author">
              {authorName}
            </a>
          </span>
        </div>
      </article>
    </section>
  );
};

export default PostCard;
