import axios from "axios";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import config from "../common/config";

const PostForm = () => {
  const [title, setTitle] = useState("");
  const [content, setContent] = useState("");
  const nav = useNavigate();
  const handleSubmit = async (e) => {
    e.preventDefault();

    const userId = sessionStorage.getItem("userId");
    const userName = sessionStorage.getItem("userName");
    const userRole = sessionStorage.getItem("userRole");

    // Construct the PostDto object, including UserDto
    const postDto = {
      title,
      content,
      likeCount: 5,
      userDto: {
        id: userId,
        userName: userName,
      },
    };
    console.log(postDto);
    try {
      const token = sessionStorage.getItem("token");
      const apiUrl = config.apiUrl;
      const response = await axios.post(
        `${apiUrl}:4000/api/v1/blogs`,
        postDto,
        {
          headers: {
            Authorization: `Bearer ${token}`, // Attach Bearer token
            "Content-Type": "application/json",
          },
        }
      );

      if (response.status === 201) {
        console.log("Post created successfully", response.data);
        // Optionally, reset the form or redirect the user
        setTitle("");
        setContent("");
        nav("/");
      } else {
        console.error("Failed to create post");
      }
    } catch (error) {
      console.error("An error occurred while creating the post:", error);
    }
  };

  return (
    <div className="container mt-5 bg-light mb-5 p-3 rounded">
      <h2>Add New Post</h2>
      <form onSubmit={handleSubmit}>
        <div className="mb-3">
          <label htmlFor="title" className="form-label">
            Title
          </label>
          <input
            type="text"
            className="form-control"
            id="title"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            required
          />
        </div>
        <div className="mb-3">
          <label htmlFor="content" className="form-label">
            Content
          </label>
          <textarea
            className="form-control"
            id="content"
            rows="10"
            value={content}
            onChange={(e) => setContent(e.target.value)}
            required
          ></textarea>
        </div>
        <button type="submit" className="btn btn-primary">
          Save Changes
        </button>
      </form>
    </div>
  );
};

export default PostForm;
