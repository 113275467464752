import React from "react";

function BlogList({ title, content, likeCount }) {
  return (
    <div className="card mb-3">
      <div className="card-body">
        <h5 className="card-title">{title}</h5>
        <p className="card-text">{content}</p>
        <p className="card-text">
          <small className="text-muted">Likes: {likeCount}</small>
        </p>
      </div>
    </div>
  );
}

export default BlogList;
