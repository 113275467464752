import "../css/footer.css";
import githubImg from "../icons/github-icon.png";
import footerR from "../icons/footer-letter-r.png";
import stackOverflowImg from "../icons/stackoverflow-color-icon.png";
import linkedIn from "../icons/linkedin-app-icon.png";

const Footer = () => {
  const currentYear = new Date().getFullYear();
  return (
    <footer className="bg-light text-center text-lg-start ">
      <div className="container p-4">
        <div className="row">
          <div className="col-lg-6 col-md-12 col-sm-12 mb-4 mb-md-0">
            <h5 className="text-uppercase">There's More</h5>
            <p className="">
              It's not just a blog. More functions are under construction!
            </p>
            <a
              href="https://www.flaticon.com/free-icons/alpha"
              title="alpha icons"
            >
              Alpha icons created by Freepik - Flaticon
            </a>
          </div>
          <div className="col-lg-3 col-md-6 col-sm-6 col-6 mb-4 mb-md-0">
            <h5 className="text-uppercase">🔗Links</h5>
            <ul className="list-unstyled mb-0">
              <li>
                <a href="https://www.rongf.top" className="text-dark">
                  <div className="footer-item-container">
                    <img className="footer-icon" src={footerR} />
                    <div className="footer-item-caption">Personal Resume</div>
                  </div>
                </a>
              </li>
              <li>
                <a href="https://www.github.com" className="text-dark">
                  <img className="footer-icon" src={githubImg} />
                </a>
              </li>
              <li>
                <a href="#!" className="text-dark">
                  <img className="footer-icon" src={stackOverflowImg} />
                </a>
              </li>
              <li>
                <a href="#!" className="text-dark">
                  <img className="footer-icon" src={linkedIn} />
                </a>
              </li>
            </ul>
          </div>
          <div className="col-lg-3 col-md-6 col-sm-6 col-6 mb-4 mb-md-0">
            <h5 className="text-uppercase">💻</h5>
            <ul className="list-unstyled mb-0">
              <li>
                <a href="#!" className="text-dark">
                  Link 1
                </a>
              </li>
              <li>
                <a href="#!" className="text-dark">
                  Link 2
                </a>
              </li>
              <li>
                <a href="#!" className="text-dark">
                  Link 3
                </a>
              </li>
              <li>
                <a href="#!" className="text-dark">
                  Link 4
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="custom-footer text-center bg-dark text-white">
        © <span>{currentYear}</span> Alpha by Ron
      </div>
    </footer>
  );
};

export default Footer;
