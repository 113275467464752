import React from "react";
import { useNavigate } from "react-router-dom";

const CreatePostButton = ({ onClick }) => {
  const nav = useNavigate();
  const buttonClick = () => {
    nav("/posts/add");
  };
  return (
    <button
      className="btn btn-primary create-post-button"
      onClick={buttonClick}
    >
      +
    </button>
  );
};

export default CreatePostButton;
