import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

function Login({ onLogin }) {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  // const history = useHistory();
  const nav = useNavigate();

  const handleSubmit = async (event) => {
    event.preventDefault();
    // Here is the authentication logic

    if (username != "" && password != "") {
      try {
        const response = await fetch(
          "http://www.vivalava.top:4000/api/v1/authentication/authenticate",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({ username, password }),
          }
        );

        if (response.ok) {
          //const token = await response.text();
          // const data = await response.json();
          console.log(response.text);

          // const token = data.token;
          // console.log(data.userName);
          // console.log(data.userRole);
          // console.log("Login successful");
          // localStorage.setItem("token", token);
          onLogin();
          nav("/");
          // Handle successful login here (e.g., redirect to another page)
        } else {
          console.error("Login failed");
          alert("Invalid credentials");
          // Handle failed login here (e.g., display error message)
        }
      } catch (error) {
        console.error("Error:", error);

        // Handle network errors here
      }
    } else {
      alert("Plese fill out username or password.");
    }
  };

  return (
    <div className="d-flex justify-content-center">
      <form onSubmit={handleSubmit} className="w-50">
        <div className="mb-3">
          <label htmlFor="username" className="form-label">
            Username
          </label>
          <input
            type="text"
            className="form-control"
            id="username"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
          />
        </div>
        <div className="mb-3">
          <label htmlFor="password" className="form-label">
            Password
          </label>
          <input
            type="password"
            className="form-control"
            id="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
        </div>
        <button type="submit" className="btn btn-primary">
          Login
        </button>
      </form>
    </div>
  );
}

export default Login;
