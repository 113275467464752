import style from "../src/css/login.module.css";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import config from "./common/config";
function LoginNew({ onLogin }) {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [userRole, setUserRole] = useState("");
  // const history = useHistory();
  const nav = useNavigate();

  const handleSubmit = async (event) => {
    event.preventDefault();
    // Here is the authentication logic
    const apiUrl = config.apiUrl;
    if (username != "" && password != "") {
      try {
        const response = await fetch(
          `${apiUrl}:4000/api/v1/authentication/authenticate`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({ username, password }),
          }
        );

        if (response.ok) {
          const data = await response.json();
          const token = data.token;
          console.log("Login successful");
          //localStorage.setItem("token", token);
          sessionStorage.setItem("token", token);
          sessionStorage.setItem("userName", data.userName);
          sessionStorage.setItem("userRole", data.userRole);
          sessionStorage.setItem("userId", data.userId);
          setUserRole(userRole);
          onLogin();
          nav("/");
          // Handle successful login here (e.g., redirect to another page)
        } else {
          console.error("Login failed");
          alert("Invalid credentials");
          // Handle failed login here (e.g., display error message)
        }
      } catch (error) {
        console.error("Error:", error);

        // Handle network errors here
      }
    } else {
      alert("Plese fill out username or password.");
    }
  };
  return (
    <div className={style.login}>
      <h1 className="text-dark mb-5">Login</h1>
      <form onSubmit={handleSubmit}>
        <input
          className={style.loginInput}
          type="text"
          name="u"
          placeholder="Username"
          required="required"
          value={username}
          onChange={(e) => setUsername(e.target.value)}
        />
        <input
          className={style.loginInput}
          type="password"
          name="p"
          placeholder="Password"
          required="required"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
        <button
          type="submit"
          className={`${style.btn} ${style.btnPrimary} ${style.btnBlock} ${style.btnLarge}`}
        >
          Let me in.
        </button>
      </form>
    </div>
  );
}

export default LoginNew;
