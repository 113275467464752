import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import config from "../common/config";

function PostDetail() {
  const { id } = useParams();
  const [post, setPost] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const token = sessionStorage.getItem("token");
  const apiUrl = config.apiUrl;
  useEffect(() => {
    const fetchPostDetails = async () => {
      try {
        const response = await axios.get(`${apiUrl}:4000/api/v1/blogs/${id}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setPost(response.data);
        console.log(response.data);
      } catch (error) {
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchPostDetails();
  }, [id]);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleDateString();
  };

  return (
    <div className="container mt-5 mb-5">
      <div className="card">
        <div className="card-header d-flex justify-content-between align-items-center">
          <div>
            <h3>{post.title}</h3>
            <small className="text-muted">
              By {post.userDto.userName} on {formatDate(post.updatedAt)}
            </small>
          </div>
          <div
            className="d-flex align-items-center"
            style={{ cursor: "pointer" }}
          >
            <i className="fas fa-thumbs-up me-2"></i> {post.likeCount}
          </div>
        </div>
        <div className="card-body">
          <p>{post.content}</p>
        </div>
      </div>
    </div>
  );
}

export default PostDetail;
