import React, { useEffect, useState } from "react";
import BlogList from "./blog/BlogList";
import PostList from "./blog/PostList";
import PostListNew from "./blog/PostListNew";
import PostListSingle from "./blog/PostListSingle";
import PostDetail from "./blog/PostDetail";
import PostForm from "./blog/PostForm";
import CreatePostButton from "./blog/CreatePostButton";
import axios from "axios";
import config from "./common/config";
import { Navigate, useNavigate } from "react-router-dom";
import Signup from "./common/Signup";
import style from "../src/css/home.module.css";
import PostCard from "./blog/PostCard";

function Home({ isAuthenticated }) {
  const [blogs, setBlogs] = useState([]);
  const [userRole, setUserRole] = useState("");
  const [deletePostId, setDeletePostId] = useState("");
  const nav = useNavigate();

  const handleSignup = () => {
    nav("/signup");
  };

  useEffect(() => {
    const fetchBlogs = async () => {
      //const token = localStorage.getItem("token");
      const token = sessionStorage.getItem("token");
      const apiUrl = config.apiUrl;
      const response = await fetch(`${apiUrl}:4000/api/v1/blogs`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      });
      if (!response.ok) {
        alert("the login has expired. Please log in again.");
      }
      const data = await response.json();
      setBlogs(data);
    };

    if (isAuthenticated) {
      fetchBlogs();

      const role = sessionStorage.getItem("userRole");
      setUserRole(role);
    }
  }, [isAuthenticated, deletePostId]);

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleDateString();
  };

  if (!isAuthenticated) {
    return (
      <div>
        <h1 className="text-dark">
          Please login to see the content or{" "}
          <button className="signupButton" onClick={handleSignup}>
            Sign up
          </button>
          <p className="infoPrompt">
            Tester Account: username: test, password: test
          </p>
        </h1>
      </div>
    );
  }

  const onDelete = (postId) => {
    setDeletePostId(postId);
  };

  return (
    <div className="mb-5">
      <div className={style.postsContainer}>
        {blogs.map((blog) => (
          // <PostListSingle
          //   key={blog.id}
          //   postId={blog.id}
          //   postTitle={blog.title}
          //   authorName={blog.userDto.userName}
          //   likeCount={blog.likeCount}
          //   updatedTime={formatDate(blog.updatedAt)}
          //   onDelete={onDelete}
          // />

          <PostCard
            key={blog.id}
            postId={blog.id}
            postTitle={blog.title}
            authorName={blog.userDto.userName}
            likeCount={blog.likeCount}
            updatedTime={formatDate(blog.updatedAt)}
            onDelete={onDelete}
          />

          //   <BlogList
          //     key={blog.id}
          //     title={blog.title}
          //     content={blog.contents}
          //     likeCount={blog.likeCount}
          //   />
          // <PostList />
          //   <PostListNew />
        ))}
      </div>
      {userRole === "superuser" && <CreatePostButton />}
    </div>
  );
  //   return (
  //     <div className="text-center">
  //       {isAuthenticated ? (
  //         <h1>Welcome! You are logged in.</h1>
  //       ) : (
  //         <h1>Please login to see the content.</h1>
  //       )}
  //     </div>
  //   );
}

export default Home;
